import { IReviewVideo } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import React, { RefObject } from 'react';
import Img from '../../../../../components/Img';
import ReviewAuthor from '../../ReviewAuthor';
import styles from './ReviewsVideo.module.scss';

interface ReviewsVideoProps {
  className: string,
  review: IReviewVideo,
  controlVideo: (command: string, ref: RefObject<HTMLIFrameElement>) => void,
  showVideo: boolean,
  setShowVideo: (value: boolean) => void,
}

const ReviewsVideo = React.forwardRef<HTMLIFrameElement, ReviewsVideoProps>(({
  className, review, controlVideo, showVideo, setShowVideo,
}, ref) => (
  <div
    className={classnames(styles.review, className)}
  >
    {!showVideo ? (
      <button
        type='button'
        onClick={() => {
          setShowVideo(true);
        }}
      >
        <Img
          src={review?.clipPreviewImage?.url}
          alt={review.clipPreviewImage.alternativeText || 'review video preview'}
          className={styles.preview}
        />
        <ReviewAuthor
          avatar={review?.author?.avatar}
          name={review?.author?.name}
          position={review?.author?.position}
          className={styles.author}
          photoClassName={styles.author__avatar}
          nameClassName={styles.author__name}
          positionClassName={styles.author__position}
        />
      </button>
    ) : (
      <div
        className={styles.video}
      >
        <iframe
          ref={ref}
          width='950'
          height='534'
          src={`${review.clip}?rel=0&enablejsapi=1`}
          title={`${review.author.name} feedback`}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen
          onLoad={() => controlVideo('playVideo', ref as RefObject<HTMLIFrameElement>)}
        />
      </div>
    )}
  </div>
));

export default React.memo(ReviewsVideo);
