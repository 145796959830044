import classnames from 'classnames';
import Img from '../../../../components/Img';
import styles from './ReviewAuthor.module.scss';

interface ReviewAuthorProps {
  avatar: {
    url: string,
    alternativeText: string | null,
  },
  name: string,
  position: string,
  className?: string,
  photoClassName?: string,
  nameClassName?: string,
  positionClassName?: string,
}

const ReviewAuthor = ({
  avatar, name, position, className, photoClassName, nameClassName, positionClassName,
}: ReviewAuthorProps) => (
  <div className={classnames(styles.author, className)}>
    <div className={classnames(styles.author__avatar, photoClassName)}>
      <Img
        src={avatar?.url}
        alt={avatar?.alternativeText || name}
      />
    </div>
    <div className={styles.author__content}>
      <p className={classnames(styles.author__name, nameClassName)}>{name}</p>
      <p className={classnames(styles.author__position, positionClassName)}>{position}</p>
    </div>
  </div>
);

export default ReviewAuthor;
