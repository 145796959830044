import { IClutch } from '@sapientpro/sapientpro-data-models';
import { useAtomValue } from 'jotai';
import Img from '../../../../components/Img';
import { theme } from '../../../../store/theme';

import styles from './ClutchReviews.module.scss';

const ClutchReviews = ({ data }: { data: IClutch }) => {
  const appTheme = useAtomValue(theme);

  return (
    <div className={styles.review}>
      <img
        src={`/media/clutch_bg_${appTheme}.svg`}
        alt='clutch'
        className={styles.clutchBg}
      />
      <Img
        src={`/media/clutch_${appTheme}.svg`}
        alt='clutch'
        width={172}
        height={49}
        className={styles.logo}
      />
      <div className={styles.content}>
        <div className={styles.stars}>
          {new Array(5).fill(0).map((item, index) => (

            <svg // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={styles.star}
            >
              <use
                xlinkHref='/media/star.svg#star'
                href='/media/star.svg#star'
              />
            </svg>
          ))}
        </div>
        <p className={styles.rating}>
          {data.stars}
          {' '}
          out of 5, based on
          {' '}
          <a
            href={data.link}
            className={styles.rating__link}
          >
            {data.numberOfReviews}
            {' '}
            reviews
          </a>
        </p>
        <p className={styles.text}>
          {data.quote}
        </p>
      </div>
    </div>
  );
};

export default ClutchReviews;
